//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import { mapGetters, mapActions } from "vuex";
import eventBus from "@/plugins/eventBus";
am4core.useTheme(am4themes_animated);

export default {
  name: "engagement",
  data() {
    return {
      showChart: true,
      showDuration: false,
      showInnerLoading: false,
      isCall: false,
      engagments_data: [],
      
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: "getFilterDate",
      currentAIAccount: "getCurrentAIAccount",
      currentUser: "getCurrentUser",
      darkTheme: "getDarkTheme",
    }),
  },
  methods: {
    ...mapActions({
      requestUserEngagement: "requestUserEngagement"
    }),
    createCombinedChart() {
      const that = this;
      if (this.chart) {
        this.chart.dispose();
      }
      if (this.darkTheme === true) {
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.unuseTheme(am4themes_dark);
      }
      // Create chart instance
      const chart = am4core.create(this.$refs.combinedChart, am4charts.XYChart);

  

      // Add data
      chart.data = that.engagments_data
      // console.log(chart.data)

      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      //dateAxis.renderer.grid.template.location = 0;
      //dateAxis.renderer.minGridDistance = 30;

      let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = "Messages";

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.title.text = "Intents";
      
      valueAxis2.renderer.opposite = true;
      valueAxis2.renderer.grid.template.disabled = true;

      // Create series
      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.dataFields.valueY = "users";
      series1.dataFields.dateX = "date";
      series1.yAxis = valueAxis1;
      series1.name = "Users";
      series1.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
      series1.fill = chart.colors.getIndex(0);
      series1.strokeWidth = 0;
      series1.clustered = false;
      series1.columns.template.width = am4core.percent(40);

      let series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.dataFields.valueY = "messages";
      series2.dataFields.dateX = "date";
      series2.yAxis = valueAxis1;
      series2.name = "Messages";
      series2.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
      series2.fill = chart.colors.getIndex(0).lighten(0.5);
      series2.strokeWidth = 0;
      series2.clustered = false;
      series2.toBack();

      let series3 = chart.series.push(new am4charts.LineSeries());
      series3.dataFields.valueY = "visits";
      series3.dataFields.dateX = "date";
      series3.name = "Visits";
      series3.strokeWidth = 2;
      series3.tensionX = 0.7;
      series3.yAxis = valueAxis2;
      series3.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";

      let bullet3 = series3.bullets.push(new am4charts.CircleBullet());
      bullet3.circle.radius = 3;
      bullet3.circle.strokeWidth = 2;
      bullet3.circle.fill = am4core.color("#fff");

      let series4 = chart.series.push(new am4charts.LineSeries());
      series4.dataFields.valueY = "intents";
      series4.dataFields.dateX = "date";
      series4.name = "Intents";
      series4.strokeWidth = 2;
      series4.tensionX = 0.7;
      series4.yAxis = valueAxis2;
      series4.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
      series4.stroke = chart.colors.getIndex(0).lighten(0.5);
      series4.strokeDasharray = "3,3";

      let bullet4 = series4.bullets.push(new am4charts.CircleBullet());
      bullet4.circle.radius = 3;
      bullet4.circle.strokeWidth = 2;
      bullet4.circle.fill = am4core.color("#fff");

      let series5 = chart.series.push(new am4charts.LineSeries());
      series5.dataFields.valueY = "time";
      series5.dataFields.dateX = "date";
      series5.name = "Time";
      series5.strokeWidth = 2;
      series5.tensionX = 0.7;
      series5.yAxis = valueAxis2;
      series5.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
      series5.stroke = chart.colors.getIndex(0).lighten(0.5);
      series5.strokeDasharray = "3,3";
      // Add cursor
      chart.cursor = new am4charts.XYCursor();

      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";

      // Add scrollbar
      // chart.scrollbarX = new am4charts.XYChartScrollbar();
      // chart.scrollbarX.series.push(series1);
      // chart.scrollbarX.series.push(series3);
      // chart.scrollbarX.parent = chart.bottomAxesContainer;

      that.chart = chart;
    },
    updateEngagmentData(data) {
    const that = this;
    that.showInnerLoading = true
    that.requestUserEngagement(data).then((resp) => {
      that.engagments_data = resp
    }).finally(() => {
    that.showInnerLoading = false
      that.createCombinedChart()
    })
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        that.isCall = false;
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
          from: "onDateUpdate",
        };
        that.updateEngagmentData(data);
      } else {
        console.log("invalid date");
      }
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: "onAccountUpdate",
      };
      that.isCall = false;
      that.updateEngagmentData(data);
    },
    isValid(value) {
      if (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    showChart(newVal) {
      const that = this;
      if (newVal == false) {
        if (that.chart) {
          that.chart.dispose();
        }
      }
    },
    darkTheme() {
      this.createCombinedChart();
    },
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: "mounted",
    };
    that.updateEngagmentData(data)
  },
  created() {
    eventBus.$on("updateDate", this.onDateUpdate);
    eventBus.$on("updateAIAccount", this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  },
};
